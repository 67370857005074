define("discourse/plugins/discourse-pkv/discourse/components/news-topic", ["exports", "discourse/lib/url", "ember-addons/ember-computed-decorators", "@ember/component"], function (_exports, _url, _emberComputedDecorators, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: 'news-topic',
    displayUser(posters) {
      return posters[0].user;
    }
  }, [["method", "displayUser", [(0, _emberComputedDecorators.default)('topic.posters')]]]));
});