define("discourse/plugins/discourse-pkv/discourse/routes/home", ["exports", "discourse/lib/ajax", "discourse/models/topic-list", "discourse/routes/discourse"], function (_exports, _ajax, _topicList, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    showFooter: true,
    model() {
      return (0, _ajax.ajax)(`/home`);
    },
    setupController(controller, model) {
      let props = {};
      if (model) {
        if (model.news_topic_list) {
          model.news_topic_list.topic_list = model.news_topic_list.home_topic_list;
          props['newsTopics'] = _topicList.default.topicsFrom(this.store, model.news_topic_list);
        }
        if (model.team) {
          props['team'] = model.team;
        }
      }
      controller.setProperties(props);
    }
  });
});